import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import MetaInfo from 'vue-meta-info'
import router from './router'
import 'lib-flexible/flexible'
import 'animate.css'
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(MetaInfo)

new Vue({
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
  },
  router:router
}).$mount('#app')
