<template>
    <div class="footer">
        <div class="container">
            <div class="footer-top">
                <div class="topL">
                    <img src="../../assets/images/logo_f.png" alt="" />
                    <div class="info">
                        <strong>Business Consulting</strong><br/>
                        Tel: +86-579-86588826 <span>(Workday 8:30-17:00)</span><br/>
                        <div style="display: flex;">Mail: <a href="mailto:solar@dmegc.com.cn">solar@dmegc.com.cn</a></div>
                        Dutch branch<br>
                        Tel: +31 (0) 8 58200765<br>
                        <div style="display: flex;">Mail: <a href="mailto:contact@dmegc.eu">contact@dmegc.eu</a></div>
                        <strong>Service support</strong><br/>
                        Tel: +86-579-86310330 <span>(Workday 8:30-17:00)</span><br/>
                        <div style="display: flex;">Mail: <a href="mailto:service@dmegc.com.cn">service@dmegc.com.cn</a></div>
                    </div>
                    <div class="share">
                        <!-- <a href="##"><i class="icon1"></i></a> -->
                        <a :href="item.link_url"
                            v-for="item in shareList"
                            :key="item.id">
                            <img :src="item.pic" alt="">
                        </a>
                    </div>
                </div>
                <div class="topR">
                    <div class="nav">
                        <ul>
                            <li class="motion"><router-link to="/solution">Solutions</router-link></li>
                            <li class="motion"><router-link to="/product">Products</router-link></li>
                            <li class="motion"><router-link to="/innovation">Innovation</router-link></li>
                            <li class="motion"><router-link to="/download">Download</router-link></li>
                            <li class="motion"><router-link to="/news">News</router-link></li>
                            <li class="motion"><router-link to="/about">About us</router-link></li>
                            <li class="motion"><a href="mailto:solar@dmegc.com.cn" target="_blank">Contact</a></li>
                        </ul>
                    </div>
                    <div class="nav">
                        <ul>
                            <li class="motion"><router-link to="/module">PVmodule Authenticity</router-link></li>
                            <li class="motion"><router-link to="/dealer">Dealer Query</router-link></li>
                        </ul>
                    </div>
                    <div class="form">
                        <h4>Business Consulting</h4>
                        <div class="item">
                            <input type="text" class="text" name="area" v-model="conditionObj.title" autocomplete="off" placeholder="Area of your business" />
                        </div>
                        <div class="item">
                            <input type="text" class="text" name="email" v-model="conditionObj.email" autocomplete="off" placeholder="Email address" />
                        </div>
                        <div class="item">
                            <textarea name="message" v-model="conditionObj.detail" placeholder="Message"></textarea>
                        </div>
                        <div class="item-bot">
                            <input type="button" value="SEND MESSAGE" class="btn" @click="updateInfo" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bot">
                <p>© 2024 DMEGC Solar Energy. All rights reserved. <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备05051364-6号</a></p>
                <div class="fcc">
                    <p class="policy" @click="showPop(0)">Privacy Policy</p>
                    <p class="policy" @click="showPop(1)">Legal Statement</p>
                </div>
                <p>Powered by <a href="https://www.lebang.com/" target="_blank">LEBANG.</a></p>
            </div>
        </div>
        <div class="pop" v-show="popFlag">
            <div class="pop-main">
                <a href="javascript:void(0);" class="close" @click="closePop"></a>
                <div class="box">
                    <div class="title">{{ popTitle }}</div>
                    <div class="scroll">
                        <vuescroll :ops="ops">
                            <div class="content" v-html="popInfo"></div>
                        </vuescroll>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vuescroll from 'vuescroll'
import { updateBusinessInfo, getArticleInfo, getBannerList } from '../../request/api'
export default {
    name:'Footer',
    components:{
        vuescroll
    },
    data(){
        return {
            conditionObj:{
                title:'',
                email:'',
                detail:''
            },
            shareList:[],
            popFlag:false,
            policy:'',
            legal:'',
            popInfo:'',
            popTitle:'',
            ops:{
                vuescroll:{
                    mode: 'native',
                    detectResize: true,
                    locking: true
                },
                rail:{
                    background: '#E3E3E3',
                    opacity: 1,
                    size: '6px',
                    specifyBorderRadius: '6px'
                },
                bar:{
                    onlyShowBarOnScroll: false,
                    keepShow: true,
                    background:'#E60011',
                    specifyBorderRadius: '6px',
                    size: '6px'
                }
            }
        }
    },
    mounted(){

        getBannerList({ cate:'share'}).then( res => {

            this.shareList = res.data
        })

        getArticleInfo({ id: 10001}).then( res => {

            this.legal = res.data
        })

        getArticleInfo({ id: 10000}).then( res => {

            this.policy = res.data
        })
    },
    methods: {
        updateInfo() {

            updateBusinessInfo(this.conditionObj).then( res => {

                if( res.flag ) {

                    this.conditionObj.title = ''
                    this.conditionObj.email = ''
                    this.conditionObj.detail = ''
                }else {

                    console.log( res.msg)
                }


            })

        },
        showPop( num ) {

            this.popFlag = true

            if( num === 0 ) {

                this.popTitle = this.policy.title
                this.popInfo = this.policy.detail

            } else {

                this.popTitle = this.legal.title
                this.popInfo = this.legal.detail
            }
        },
        closePop(){

            this.popFlag = false
        }
    }
};
</script>

<style lang="scss" scoped>
.footer{
    background: #333;
    .footer-top{
        padding: 5.2083vw 0 3.125vw;
        display: flex;
        justify-content: space-between;
    }
    .topL{
        img{
            display: block;
            width: 175px;
        }
        .info{
            font-size: 15px;
            line-height: 25px;
            color: #fff;
            font-weight: 300;
            margin-top: 2.0833vw;
            strong{
                font-size: 16px;
                font-weight: bold;
            }
            span{
                font-size: 12px;
            }
            a{
                display: block;
                color: #fff;
                text-decoration: underline;
                font-weight: 300;
            }
        }
        .share{
            margin-top: 2.0833vw;
            display: flex;
            a{
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 16px;
                img{
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .topR{
        width: 57.8125vw;
        padding-left: 5.9896vw;
        box-sizing: border-box;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: space-between;
        .nav{
            li{
                margin-bottom: 10px;
                position: relative;
                left: 0;
                &:hover{
                    left: -5px;
                }
            }
            a{
                display: block;
                font-size: 18px;
                line-height: 30px;
                color: #fff;
                font-weight: 600;
            }
        }
        .form{
            width: 28.8542vw;
            h4{
                font-size: 24px;
                font-weight: 600;
                color: #fff;
                line-height: 30px;
                margin-bottom: 10px;
            }
            .item{
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                padding-top: 10px;
            }
            .text{
                display: block;
                width: 100%;
                height: 48px;
                border:none;
                background: none;
                font-size: 16px;
                color: #848F9E;
            }
            textarea{
                display: block;
                width: 100%;
                height: 48px;
                border:none;
                background: none;
                font-size: 16px;
                color: #848F9E;
                line-height: 30px;
                height: 88px;
                padding-top: 10px;
            }
            .item-bot{
                margin-top: 2.3958vw;
                .btn{
                    display: flex;
                    width: 198px;
                    height: 50px;
                    box-sizing: border-box;
                    border:1px solid #fff;
                    font-size: 16px;
                    color: #fff;
                    background: none;
                    align-items: center;
                    justify-content: center;
                    &:hover{
                        background: #E60011;
                        border-color: #E60011;
                    }
                }
            }
        }
    }
    .footer-bot{
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 89px;
        p,a{
            font-size: 16px;
            line-height: 26px;
            color: #fff;
            font-weight: 300;
        }
        .policy{
            margin-right: 2.0833vw;
            cursor: pointer;
        }
    }
    .pop{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 1001;
    }
    .pop-main{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 72.9167vw;
        transform: translate(-50%, -50%);
        background: #fff;
        .box{
            padding: 1.6667vw 2.0833vw 2.6042vw 2.6042vw;
        }
        .title{
            font-size: 1.0417vw;
            line-height: 1.25vw;
            text-align: center;
            font-weight: bold;
            color: rgba(0,0,0,0.8);
        }
        .close{
            width: 1.25vw;
            height: 1.25vw;
            display: block;
            background: url('../../assets/images/close_p.png') no-repeat;
            background-size: cover;
            position: absolute;
            right: 2.9167vw;
            top: 1.6667vw;
        }
        .content{
            font-size: 0.8333vw;
            line-height: 1.1458vw;
            color: rgba(0,0,0,0.6);
            padding-right: 18px;
            p{
                margin-bottom: 0.8333vw;
            }
        }
    }
    .scroll{
        height: 31.25vw;
        margin-top: 1.25vw;
    }
}
@media screen and (max-width: 750px) {
    .footer{
        .footer-top{
            padding: .6rem 0;
            display: block;
            position: relative;
            .topL{
                img{
                    width: 2.16rem;
                }
                .info{
                    margin-top: 6.8rem;
                    font-size: .3rem;
                    line-height: .5rem;
                    div{
                        margin-bottom: .3rem;
                    }
                }
                .share{
                    margin-top: 2.1rem;
                }
            }
            .topR{
                position: absolute;
                left: 0;
                top: 1.5rem;
                width: 100%;
                padding-left: 0;
                border-left: none;
                .form{
                    width: 100%;
                    h4{
                        font-size: .32rem;
                        line-height: .6rem;
                        font-weight: normal;
                    }
                    .item{
                        padding-top: 0;
                    }
                    .text{
                        height: .98rem;
                        font-size: .26rem;
                    }
                    textarea{
                        height: 1.94rem;
                        font-size: .26rem;
                        padding-top: .2rem;
                    }
                    .item-bot{
                        margin-top: .4rem;
                        .btn{
                            width: 2.92rem;
                            height: .7rem;
                            font-size: .24rem;
                        }
                    }
                }
                .nav{
                    position: absolute;
                    left: 0;
                    bottom: -5.1rem;
                    &:nth-child(2){
                        bottom: -5.6rem;
                    }
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                    }
                    li{
                        margin-bottom: 0;
                        margin-right: .2rem;
                        position: relative;
                        &::after{
                            content: '';
                            width: 2px;
                            height: .32rem;
                            position: absolute;
                            right: 0;
                            top: .1rem;
                            background: #fff;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                    }
                    a{
                        font-size: .28rem;
                        line-height: .52rem;
                        padding-right: .2rem;
                    }
                }
            }

        }
        .footer-bot{
            height: auto;
            display: block;
            padding: .4rem 0 .6rem;
            p,
            a{
                font-size: .24rem;
                line-height: .44rem;
            }
            .policy{
                display: none;
            }
        }
    }
}
</style>